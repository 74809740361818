/* eslint-disable no-unreachable */
import {useMutation, useQuery} from '@apollo/client';
import {Box, Button, Text} from 'native-base';
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import NavigateBar from '../../components/NavigateBar';
import {CHAT_MODULE} from '../../constants/codeConstants';
import {sendCommandToEngine} from '../../core/chat/chatServices';
import {GET_CONVERSATION_FLOW} from '../../graphql/queries/diary';
import {RECORD_DISPLAY_DETAIL_PROGRESS} from '../../graphql/queries/displayDetails';
import {getWidthAndHeight, isWeb} from '../../helpers/utils/utils';
import {
  ExtraInfoContext,
  initialize,
  PushStateContext,
  SessionContext,
  UserContext,
  userInitialize,
} from '../../hooks/SessionContext';
import ChatMain from './ChatMain';
import {
  logMainScreen,
  logRudderEvent,
  resetRudder,
} from '../../helpers/analytics/fbAnalytics';
import {GET_USER_DATA, UPDATE_PROFILING} from '../../graphql/queries/users';
import SmallModalMinimalist from '../../components/SmallModalMinimalist';
import RatingExercise from '../../components/Exercises/RatingExercise';
import {MODULES} from '../../core/featureFlagService';
import usePrivileges from '../../hooks/useUserSubscriptionAccess';
import {singOut} from '../../core/firebase/firebaseAuth';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {navigateTo, ROUTE_NAMES} from '../../navigation/MainNavigator';
import {clearItems} from '../../hooks/useAsyncStorageData';
import {reportError} from '../../helpers/crashlytics/fbCrashlytics';

/**
 * Screen de ChatExercise
 * routeParams:
 *  onBoarding: cuando el usuario viene del onBoarding (primer conversación con MS)
 */

export default function ChatExercise({route, navigation}) {
  const {
    conversationFlow: cf,
    onBoarding,
    displayDetailId,
    exercise,
  } = route?.params || {};
  const {pushState} = useContext(PushStateContext);
  const title = route?.params?.title || '';
  const {user, setUser} = useContext(UserContext);
  const {session, setSession} = useContext(SessionContext);
  const {extraInfo, setExtraInfo} = useContext(ExtraInfoContext);
  const [conversationFlowDetails, setConversationFlowDetails] = useState(null);
  const [isFinishExercise, setIsFinishExercise] = useState(false);
  const [showModalRating, setShowModalRating] = useState(false);
  const [showThanks, setShowThanks] = useState(false);
  const {t, i18n} = useTranslation();
  const finishTextButton = onBoarding ? t('buttons:next') : undefined;
  const {width} = getWidthAndHeight();
  const insets = useSafeAreaInsets();
  const {isModuleActive} = usePrivileges();
  // useState para checar si el chat se carga con historial
  const [chatAsLastMessages, setChatAsLastMessages] = useState(false);
  const [lastNumberMessages, setLastNumberMessages] = useState(10);

  const [conversationFlow, setConversationFlow] = useState(null);
  const [isWebRegister, setIsWebRegister] = useState(false);
  const [webNomId, setWebNomId] = useState();
  const [webStates, setWebStates] = useState();
  const [companyVariables, setCompanyVariables] = useState();
  const [onboardingConversationFlow, setOnboardingConversationFlow] =
    useState(null);

  /*
          const newConversationFlow = conversationFlow
            ? conversationFlow
            : isWeb()
            ? webConfig?.conversationFlowDefault
            : null;
           */

  const [recordDisplayDetailProgress] = useMutation(
    RECORD_DISPLAY_DETAIL_PROGRESS,
    {
      onError: callbackError,
      onCompleted: callBackSuccess,
    },
  );

  const {
    data: profileData,
    client,
    refetch,
  } = useQuery(GET_USER_DATA, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
  });

  const {data, error} = useQuery(GET_CONVERSATION_FLOW, {
    variables: {
      name: conversationFlow,
    },
    skip: !conversationFlow,
  });

  const [updateProfiling] = useMutation(UPDATE_PROFILING, {});

  useEffect(() => {
    logMainScreen('chat_exercise', pushState);

    if (!isWeb()) {
      // navigation.setOptions(initializeNavbar(title, outToScreen, null, true));
    }
    if (onBoarding) {
      logRudderEvent({
        name: 'anonymousLogin',
        step: '2.A FirstChat',
        screen: 'ChatExercise',
        pushState,
        extraProperties: {
          conversationFlow,
        },
      }).then(() => {
        console.log('🟢', 'anonymousLogin');
      });
    }
  }, []);

  useEffect(() => {
    if (error) {
      console.log('🔴', error);
    }
  }, [error]);

  useEffect(() => {
    if (data && data?.conversationFlow) {
      console.log('🟢', JSON.stringify(data, null, 2));
      const {id, name, displayDetails} = data?.conversationFlow;
      setConversationFlowDetails({
        id,
        name,
        title: displayDetails?.title || '',
        shortDescription: displayDetails?.shortDescription || '',
      });
    }
  }, [data]);

  useEffect(() => {
    if (profileData && profileData?.profile) {
      let workCompanyVariables = profileData?.profile?.workCompany?.variables;
      workCompanyVariables = JSON.parse(workCompanyVariables) || {};

      if (!isWeb()) {
        setConversationFlow(cf);
        setCompanyVariables(workCompanyVariables);
        return;
      }
      // Conversation flow for web
      const initialConversationFlow = async (): string => {
        console.log('profileData', JSON.stringify(profileData, null, 2));
        let flagForHistory = false;

        let {extraAppData} = profileData.profile;
        extraAppData = JSON.parse(extraAppData);

        const {
          WebShowLastMessages,
          WebForceOnboarding,
          WebNavigateToEvaluations,
          WebAlwaysShowMenu,
          WebNomId,
          WebDefaultConversationFlow,
          WebOnboardingConversationFlow,
          WebHideOptionMenu,
          WebHideFinishButton,
        } = workCompanyVariables;

        setWebStates({
          WebNavigateToEvaluations,
          WebAlwaysShowMenu,
          WebHideOptionMenu,
          WebHideFinishButton,
        });

        setOnboardingConversationFlow(WebOnboardingConversationFlow);

        setWebNomId(WebNomId);

        // si tiene bandera de WebShowLastMessages, entonces se carga el historial y pone el numero de last messages
        if (WebShowLastMessages && WebShowLastMessages > 0) {
          flagForHistory = true;
          setLastNumberMessages(Number(WebShowLastMessages));
        }

        console.log(
          'completoel onboarding',
          extraAppData?.hasCompletedOnboarding,
        );
        // Si la bandera WebForceOnboarding es falsa entra, no importa si acabó o no el onboarding
        if (
          WebForceOnboarding === 'false' ||
          (extraAppData && extraAppData?.hasCompletedOnboarding)
        ) {
          // significa que ya terminó el onboarding, entonces regresamos bandera
          setChatAsLastMessages(flagForHistory);
          return WebDefaultConversationFlow;
        }
        // New register flow
        setIsWebRegister(true);
        // si no ha terminado el onboarding, entonces no importa bandera, sí o sí se carga el onboarding
        setChatAsLastMessages(false);

        return WebOnboardingConversationFlow;
      };
      initialConversationFlow().then(flow => {
        console.log('initialConversationFlow', flow);
        setConversationFlow(flow);
      });
    }
    return () => {};
  }, [profileData]);

  function onCompletedUpdateProfile(data) {
    console.log('🟢', 'updateProfiling');
    console.log('🟢', data);
    // cambiar el hasCompletedOnboarding a true.
    setIsWebRegister(false);
  }

  function callbackError(error) {
    console.log('🔴', error);
  }

  async function callBackSuccess(data) {
    console.log('🟢 sucesss', JSON.stringify(data, null, 2));
    const displayDetail =
      data?.addDisplayDetailRecord?.displayDetailRecord?.displayDetails || {};
    await logRudderEvent({
      name: 'exercises',
      step: '3.B FinishExercise',
      screen: 'Exercise',
      extraFrom: {
        component: 'ChatExercise',
      },
      extraProperties: {
        exercise_id: displayDetailId,
        exercise_name: title,
        exercise_category_id: displayDetail?.group?.id,
        exercise_category_name: displayDetail?.group?.name,
        exercise_type: displayDetail?.contentType,
      },
      pushState,
    });
  }

  async function sendFeedback(rating, comment = null) {
    await logRudderEvent({
      name: 'feedback',
      step: '5.E RatingExercise',
      screen: 'ChatExercise',
      extraFrom: {
        component: 'RatingExercise',
      },
      extraProperties: {
        type: 'exercise',
        content_type: exercise?.contentType,
        id: displayDetailId,
        name: title,
        category_id: exercise?.categoryId,
        category_name: exercise?.categoryName,
        internal_name: exercise?.internalName,
        comment: comment || null,
        stars: rating,
      },
      pushState,
    });
    console.log('enviando...', rating, comment);
    setShowThanks(true);
  }

  // finish global para onboarding o web
  async function finish() {
    try {
      if (isWebRegister) {
        await updateProfiling({
          variables: {
            profile: {
              extraAppData: JSON.stringify({
                hasCompletedOnboarding: true,
              }),
            },
          },
        });
      }

      if (onBoarding) {
        refetch()
          .then(data => {
            setUser({...user, userCMS: data?.profile});
          })
          .finally(async () => {
            setSession({
              ...session,
              anonymousIntro: false,
            });
            logRudderEvent({
              name: 'anonymousLogin',
              step: '2.Z FirstChat',
              pushState,
              screen: 'ChatExercise',
              extraProperties: {
                conversationFlow,
              },
            });

            let route, params;
            if (companyVariables?.force_profiling_onboarding) {
              route = ROUTE_NAMES.profiling;
              params = {};
            } else {
              const objectCompany = JSON.parse(
                companyVariables?.redirect_after_intro,
              );
              route = objectCompany?.name?.route;
              params = objectCompany?.name?.variables;
            }

            navigation.navigate(route, params);
          });
      }

      await sendCommandToEngine(user?.userFB?.uid, '/silent_reset');
    } catch (error) {
      console.log('Error in finish function:', error);
      reportError(
        'ChatExercise',
        {extra: {isWebRegister, onBoarding}},
        'finish',
        error,
      );
    }
  }

  // finish solo para exercises
  async function finishExercise() {
    if (!displayDetailId) return console.log('No hay displayDetailId');
    setShowModalRating(true);
    const variables = {
      displayDetailId: displayDetailId,
      percentage: 100.0,
      currentTime: 100.0,
    };
    // Aqui enviamos
    console.log('🧡 ', variables);
    await recordDisplayDetailProgress({
      variables,
    });
  }

  async function back() {
    if (extraInfo?.isPlaying) {
      setExtraInfo({...extraInfo, floatAudio: true});
    }
    await finish();
    navigation.goBack();
  }

  async function logout() {
    await client.clearStore();
    try {
      await singOut();
    } catch (e) {
      console.error('error en logout de web:', e);
    }
    await AsyncStorage.setItem('user-language', i18n?.language);
    AsyncStorage.getItem('registerPrefix').then(async registerPrefix => {
      setSession({...initialize});
      setUser({...userInitialize});

      if (registerPrefix) {
        await clearItems([]);
        navigateTo(ROUTE_NAMES.employeeLogin, {registerPrefix}, true);
        return;
      }
      await clearItems([]);
      resetRudder();
      navigateTo(ROUTE_NAMES.login, {reset: true});
    });
  }

  console.log(
    'conversationFlowDetails',
    conversationFlowDetails,
    lastNumberMessages,
    chatAsLastMessages,
  );
  if (!conversationFlowDetails) return null;

  return (
    <>
      <Box
        h={'100%'}
        flex={1}
        pt={insets.top === 0 ? 5 : Math.min(insets.top, 10)}>
        <Box
          flexDir={'row'}
          w={width}
          h={
            title?.length > 20 || conversationFlowDetails?.title?.length > 20
              ? '9%'
              : '5%'
          }
          px={3}>
          <NavigateBar
            backEvent={!onBoarding && !isWeb() ? back : null}
            headerTitle={conversationFlowDetails?.title || title || 'Mindsurf'}
          />
          {isWeb() && (
            <Button
              testID="logout-btn"
              onPress={logout}
              bg={'primary.100'}
              borderRadius={'full'}
              py={2}
              my={1}
              size={'sm'}
              alignSelf={'flex-end'}>
              {t('chat:logout')}
            </Button>
          )}
        </Box>
        <Box
          h={'95%'}
          bg={'secondary.100'}
          borderTopRadius={45}
          overflow={'hidden'}
          pb={3}>
          <ChatMain
            conversationFlow={conversationFlow}
            chatModule={CHAT_MODULE.EXERCISES}
            finishTextButton={finishTextButton}
            onFinishConversation={async () => {
              await finish();
              if (!onBoarding) navigation.goBack();
            }}
            onLastMessage={async () => {
              // aqui se ejecuta la mutacion y los eventos de ejercicios
              // solo se ejecuta cuando damos finish, no al hacer back
              await finishExercise();
            }}
            chatAsLastMessages={chatAsLastMessages}
            lastNumberMessages={lastNumberMessages}
            webStates={webStates}
            webNomId={webNomId}
            onboardingConversationFlow={onboardingConversationFlow}
            isFinishExercise={isFinishExercise}
            sendFeedback={sendFeedback}
          />
        </Box>
        <Box h={insets.bottom} bg={'secondary.100'} />
        {isModuleActive(MODULES.RAITING_EXERCISE) && (
          <SmallModalMinimalist
            showModal={showModalRating}
            setShowModal={setShowModalRating}>
            {showThanks ? (
              <Text
                testID="showThanks"
                fontWeight={800}
                fontSize={'xl'}
                textAlign={'center'}>
                {t('exercises:modalMessage')}
              </Text>
            ) : (
              <RatingExercise
                sendFeedback={sendFeedback}
                testID={'raiting-card'}
              />
            )}
          </SmallModalMinimalist>
        )}
      </Box>
    </>
  );
}
