export default {
  typeDescription: 'Ejercicio de {{type}}',
  duration: 'Duración:',
  minutes: 'minutos',
  emptyHistory:
    'Aquí aparecerá un registro de las veces que has hecho este ejercicio o evaluación.',
  historyTitle: 'Historial de uso',
  seeAll: 'Ver todo',
  video: 'Video',
  chat: 'Chat',
  audio: 'Audio',
  title: 'Ejercicios',
  titleCategory: 'Categorías',
  noDD: 'Todavía no hay contenido de este tipo... pero pronto lo habrá. 😄',
  noFav:
    'Agrega ejercicios a tus favoritos para que puedas acceder a ellos ⚡ así de rápido ⚡.',
  titleRating: '¿Cómo calificas la ayuda que te dio este ejercicio?',
  commentRating: 'Dejar un comentario',
  textareaRating: '¿En qué podemos mejorar?',
  sendRating: 'Enviar',
  rating0: 'No me ayudó en nada',
  rating1: 'No me ayudó casi nada',
  rating2: 'Me ayudó poquito',
  rating3: 'La ayuda es regular',
  rating4: 'La ayuda es buena',
  rating5: 'Me ayudó mucho',
  modalMessage: '¡Gracias por tus comentarios! 🙌',
  titleTikTok: '¿Un respiro para tu mente?',
  trendsTitle: 'Platica con Mindsurf',
};
