/* eslint-disable prettier/prettier */
import {gql} from '@apollo/client';

export const GET_HOME_DATA = gql`
  query displayDetailGroupsAndNextTherapyMeetings {
    displayDetailGroups(groupType: "HOME") {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          name
          displayDetails {
            id
            title
            contentType
            emoji
            conversationFlow {
              name
            }
            group {
              id
              name
              image {
                cdnUrl
              }
            }
          }
        }
      }
    }
    nextTherapyMeetings(first: 1) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          status
          dynamicLink
          reservationId
          date
          startTime
          meetingPatientUrl
          daysLeft
          therapyOption {
            label
          }
        }
      }
    }
  }
`;

export const GET_DISPLAY_DETAILS_GROUPS_CHIPS = gql`
  query displayDetailGroupsTrends {
    displayDetailGroups(groupType: "TRENDS") {
      edges {
        node {
          displayDetails {
            title
            emoji
            conversationFlow {
              id
              name
            }
          }
        }
      }
    }
  }
`;
