import {Box, Pressable, Text} from 'native-base';
import React from 'react';
import {isWeb} from '../../helpers/utils/utils';

const QuestionChips = ({question, onSelectChip = () => {}}) => {
  return (
    <Pressable
      testID={`chip-${question?.title}`}
      onPress={() => {
        onSelectChip(question);
      }}
      px={3}
      py={2}
      mr={2}
      my={1}
      bg={'secondary.100'}
      shadow={1}
      _pressed={{
        backgroundColor: 'secondary.50',
      }}
      borderRadius={'full'}
      style={{alignSelf: 'flex-start'}}>
      <Box flexDir={'row'}>
        <Text testID="emoji" alignSelf={'center'} pr={1}>
          {question?.emoji}
        </Text>
        <Text
          testID="title"
          numberOfLines={2}
          textAlign={'center'}
          fontSize={'sm'}
          fontWeight={800}
          fontFamily={isWeb() ? 'Quicksand' : 'quicksand'}>
          {question?.title}
        </Text>
      </Box>
    </Pressable>
  );
};

export default QuestionChips;
