import {reportError} from '../../helpers/crashlytics/fbCrashlytics';
import {database} from '../firebase/firebaseAuth';
import {getServerTimestamp} from '../cmsServices';
import {logSimpleEvent} from '../../helpers/analytics/fbAnalytics';

/** Obtener  ultimos mensajes */
export const getMessages = async (uid, numberMessages, callback, time) => {
  console.log('uid', uid);
  try {
    let ref;
    if (time) {
      ref = await getMessagesWithNode(uid, time, callback);
    } else {
      ref = await getMessagesWithLimit(uid, numberMessages, callback);
    }
    await ref.once('value', snapshot => {
      console.log('valueeeee', snapshot);
      callback(snapshot);
    });
    await ref.on('child_added', snapshot => {
      console.log('child_added', snapshot);
      callback(snapshot);
    });

    return () => {
      console.log('off: value, child_added');
      ref.off('value');
      ref.off('child_added');
    };
  } catch (e) {
    console.log('🛑 Error en mensajes: ', e);
    reportError('fb', uid, 'getMessages', e);
    return false;
  }
};

const getMessagesWithLimit = async (uid, numberMessages = 6, callback) => {
  return database
    .ref(`webbot/${uid}_v2`)
    .limitToLast(numberMessages)
    .orderByChild('server_timestamp');
};

const getMessagesWithNode = async (uid, time, callback) => {
  return database
    .ref(`webbot/${uid}_v2`)
    .startAt(time)
    .orderByChild('server_timestamp');
};

/** Desconectarnos  */
export const disconnectFromDB = (uid, callback) => {
  return database.ref(`webbot/${uid}_v2`).off('value', callback);
};

/** Desconectarnos  */
export const disconnect = uid => {
  return database.ref(`webbot/${uid}_v2`).off();
};

/** Enviar comando a Engine */
export const sendCommandToEngine = async (uid, command) => {
  return sendMessageToFB(uid, command, '', 'sendMessageToEngine');
};

/** Enviar mensaje a BD */
export const sendMessageToFB = async (
  uid,
  content,
  lastFlowName = '',
  conversationPath = 'webbot',
  isbBot = false,
) => {
  // Lógica para obtener el timestamp del servidor
  const now = new Date();
  const serverTime = await getServerTimestamp();
  const responseTime = new Date();
  const diffResponseTime = responseTime.getTime() - now.getTime();
  const diff = serverTime - now.getTime() + diffResponseTime / 2;
  // si diff es un numero entero Negativo, significa que el usuario tiene su hora adelantada.
  console.log('Diff timestamp between server (ms):', diff, conversationPath);
  logSimpleEvent('diff_timestamp_chat', {
    diff_timestamp: diff,
    server_time: serverTime,
    cellphone_time: now.getTime(),
    response_time: responseTime,
    diff_response_time: diffResponseTime,
  });
  if (diff < 0 && conversationPath === 'sendMessageToEngine') {
    console.log('🟡 El usuario tiene su hora adelantada');
    reportError('Chat Responde Null', {
      userID: uid,
      conversationFlow: lastFlowName,
      diff: diff,
    });
  }
  const time = serverTime || now.getTime();
  const chatref = `${conversationPath}/${uid}_v2/${Date.now()}`;
  let ref = database.ref(chatref);

  ref
    .set({
      bot: isbBot,
      content,
      server_timestamp: time,
      flow_name: lastFlowName,
    })
    .then(() => {
      console.log('✅ Mensaje enviado a FBBBB');
    })
    .catch(error => {
      console.log('🛑 Error en sendMessageToFB: ', error);
      // TODO exception: Manejar el tipo de error e informar al usuario
      reportError('fb', {uid, content}, 'sendMessageToFB', error);
    });
  return time;
};
/** Verificar conexión */
export const getConnection = callback => {
  let ref = database.ref('.info/connected');
  ref
    .once('value', snapshot => {
      console.log('value', snapshot);
      callback(snapshot);
    })
    .then(() => {
      console.log('✅ Mensaje enviado a FB');
    })
    .catch(error => {
      console.log('🛑 Error en sendMessageToFB: ', error);
      // TODO exception: Manejar el tipo de error e informar al usuario
      reportError('fb', {}, 'getConnection', error);
    });
  // const db = getDatabase();
  // const connectedRef = ref(db, '.info/connected');
  // onValue(connectedRef, snapshot => {
  //   callback(snapshot.val());
  // });
};
