/* eslint-disable no-bitwise */
import {Box, Center, HStack, Pressable} from 'native-base';
import React, {useState, useContext} from 'react';
import {getWidthAndHeight, isWeb} from '../../helpers/utils/utils';
import {FeatureFlagsContext} from '../../hooks/SessionContext';
import {CHAT_TYPES} from '../../constants/codeConstants';
import ResponseType from './ResponseType/ResponseType';
import IconIon from '../Icon/IconIon';
import {colors} from '../../styles/colors';

/**
 *
 * Caja de respuestas del Chat
 */

const ResponseBox = ({
  lastMessage,
  onResponse,
  disabled,
  chatModule,
  hasMenu = false,
  onStartAnswer = () => {},
  isStreaming = false,
  featureFlagMenu = false,
  showMenu,
  sendToCall,
  setShowChipsQuestion = () => {},
}) => {
  const {responses, finishingMessage, expectingResponse} = lastMessage || {};
  const [text, setText] = useState('');
  const [focusInput, setFocusInput] = useState(false);
  const {featureFlags} = useContext(FeatureFlagsContext);
  const chatAudio = featureFlags?.chatAudio;
  const {width} = getWidthAndHeight();
  const width500 = width < 500;

  //get option Arrays
  const optionsArray =
    responses !== null
      ? responses
          ?.split('/')
          ?.filter(option => option !== 'Menú Principal' && option !== '')
      : [];

  function submitText() {
    setShowChipsQuestion(false);
    onResponse(text.trim(), CHAT_TYPES.TEXT);
    setText('');
  }

  function getTypeStatus() {
    if (!expectingResponse && finishingMessage) return;
    if (optionsArray?.length < 1 || optionsArray === undefined) {
      return 'INPUT';
    }
    return 'OPTIONS';
  }

  function submitChip(option) {
    onResponse(option, CHAT_TYPES.TEXT, isStreaming);
    setText('');
  }

  function sendMessage() {
    submitText();
  }

  function onSentAudio(userFile) {
    setShowChipsQuestion(false);
    onResponse(userFile, CHAT_TYPES.AUDIO, isStreaming);
  }

  const currentProps = {
    text,
    setText,
    submitText,
    submitChip,
    sendMessage,
    disabled,
    onStartAnswer,
    isStreaming,
    optionsArray,
    hasMenu,
    onSentAudio,
    sendToCall,
    chatModule,
    chatAudio,
    setFocusInput,
    width500,
  };

  if (isWeb()) {
    return (
      <Center pb={featureFlagMenu && focusInput && width500 ? 120 : 0}>
        <Box px={3} w={'100%'} flexDir={'row'}>
          {hasMenu && !featureFlagMenu && (
            <Center w={width500 ? '15%' : '5%'}>
              <Pressable onPress={showMenu}>
                <IconIon
                  onPress={showMenu}
                  name={'menu'}
                  size={30}
                  color={colors.tertiary100}
                />
              </Pressable>
            </Center>
          )}
          <Box w={featureFlagMenu ? '100%' : '90%'}>
            <ResponseType {...currentProps} type={getTypeStatus()} />
          </Box>
        </Box>
      </Center>
    );
  }

  return (
    <Center py={3} w={width}>
      <HStack w={width} justifyContent={'space-between'}>
        <ResponseType {...currentProps} type={getTypeStatus()} />
      </HStack>
    </Center>
  );
};

export default ResponseBox;
